import Lenis from '@studio-freight/lenis'
import gsap from "gsap";

// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)
const lenis = new Lenis({
  duration: 0,
})
lenis.on('scroll', (e) => {})
function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
const anchors = document.querySelectorAll(".js-anchor");
const header = document.querySelector('.header');
anchors.forEach((anchor) => {
  anchor.addEventListener("click", (e) => {
    e.preventDefault();
    const to = e.currentTarget.getAttribute('href')
    // lenis.scrollTo( document.querySelector(to).offsetTop - (header.offsetHeight - 40));
    // lenis.scrollTo( document.querySelector(to).getBoundingClientRect().top - header.offsetHeight - 20);
    const toposition = document.querySelector(to).getBoundingClientRect().top + window.pageYOffset - (header.offsetHeight + 40);
    lenis.scrollTo(toposition);
  });
})
const smartBreakPoint = 769;
const smartBreakPointMediaQuery = "(min-width: "+smartBreakPoint+"px)";
const isSmartPhone = () => {
  // デバイス幅が640px以下の場合にスマホと判定する
  if (window.matchMedia && window.matchMedia('(max-device-width: '+(smartBreakPoint-1)+'px)').matches) {
    return true;
  } else {
    return false;
  }
}
// const pieces = document.querySelectorAll(".piece");
// const pieceBg = document.querySelector(".piece-bg");
// function pieceAnime() {
//   gsap.set(pieceBg, {opacity:0});
//   const tl = gsap.timeline(); 
//   pieces.forEach((piece,i) => {
//     gsap.fromTo(piece, {
//       x: gsap.utils.random(-100, 100),
//       y: gsap.utils.random(-100, 100),
//       opacity: 0,
//       // delay : gsap.utils.random(0.2, 2),
//       duration: .5,
//       delay: 0,
//     },{
//       x: 0,
//       y: 0,
//       opacity: 1,
//     })
//   })
//   tl.to(pieceBg, { opacity: 1})
// }
// pieceAnime()
// setInterval(pieceAnime,8000)

let mm = gsap.matchMedia();
const initParallaxCard = () => {
  const card = gsap.utils.toArray('.js-parallax-card');
  const wrapper = document.querySelector('.js-parallax-cards');
  if (card.length === 0 ) {
    return false;
  }
  mm.add(smartBreakPointMediaQuery, () => {
    gsap.set(card, { y: 300 });
    const parallaxTl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          // start: "top-=100 top",
          start: "top top",
          // end: "bottom top",
          end: wrapper.offsetHeight * 4,
          // markers: true,
          pin: true,
          scrub: 2.5,
          duration: 3,
          anticipatePin: 1,
          // invalidateOnRefresh: true,
          // animation: pinScroll,
        },
    }); 
    parallaxTl.to(card, { y:0, duration: 2, stagger: 1,delay: .5,})
    parallaxTl.to(card, { delay: 1.5,rotateY: 180, duration: 4.5, stagger: 2})
  });
}
initParallaxCard()

const scalein = gsap.utils.toArray('.js-scalein-effect1');
if (scalein.length != 0) {
  scalein.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 20,
      scale: 0
    }, {
      y:0 ,
      scale: 1,
      duration: 1,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}

const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length != 0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 20,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 2,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
